<template>
  <v-card>
    <v-container>
      <div>
        <h2>Create a new user</h2>
        <v-form v-model="validForm">
          <v-row>
            <v-col lg="6">
              <v-text-field
                v-model="id"
                :label="$t('User ID')"
                filled
                disabled
              ></v-text-field>
            </v-col>

            <v-col lg="6">
              <v-text-field
                v-model="organisationId"
                :label="$t('Organisation ID')"
                filled
                required
                disabled
                :rules="$rules.required"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col lg="6">
              <v-text-field
                v-model="firstname"
                :label="$t('Firstname')"
                filled
                required
                :rules="$rules.required"
              ></v-text-field>
            </v-col>

            <v-col lg="6">
              <v-text-field
                v-model="lastname"
                :label="$t('Lastname')"
                filled
                required
                :rules="$rules.required"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col lg="6">
              <v-text-field
                v-model="email"
                :label="$t('Email')"
                filled
                required
                autocomplete="off"
                :rules="$rules.email"
              ></v-text-field>
            </v-col>

            <v-col lg="6">
              <v-text-field
                v-model="password"
                :label="$t('Password')"
                filled
                type="password"
                required
                autocomplete="new-password"
                :rules="$rules.password"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right">
              <v-btn
                justify-end
                color="primary"
                large
                :disabled="!validForm"
                @click="createUser"
              >
                {{ $t('CREATE') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-container>
  </v-card>
</template>
<script>
import FormMixin from '@/mixins/FormMixin'

export default {
  mixins: [FormMixin],
  props: {
    inModal: Boolean
  },
  data: () => ({
    validForm: false
  }),
  computed: {
    id: {
      get() {
        return this.$store.state.users.editor.id
      },
      set(value) {
        this.$store.commit('users/set', ['editor.id', value])
      }
    },
    organisationId: {
      get() {
        return this.$store.state.users.editor.organisation
      },
      set(value) {
        this.$store.commit('users/set', ['editor.organisation', value])
      }
    },
    firstname: {
      get() {
        return this.$store.state.users.editor.firstname
      },
      set(value) {
        this.$store.commit('users/set', ['editor.firstname', value])
      }
    },
    lastname: {
      get() {
        return this.$store.state.users.editor.lastname
      },
      set(value) {
        this.$store.commit('users/set', ['editor.lastname', value])
      }
    },
    email: {
      get() {
        return this.$store.state.users.editor.email
      },
      set(value) {
        this.$store.commit('users/set', ['editor.email', value])
      }
    },
    password: {
      get() {
        return this.$store.state.users.editor.password
      },
      set(value) {
        this.$store.commit('users/set', ['editor.password', value])
      }
    }
  },
  watch: {
    validForm(value) {
      this.$emit('is-valid', value)
    }
  },
  methods: {
    async createUser() {
      let me = this
      try {
        let user = await me.$store.dispatch('users/createUser')
        me.$toast({
          type: 'success',
          message: `New user "${user.firstname}" "${user.lastname}" created`
        })
        await me.$redirect({
          name: 'users',
          params: { organisationId: me.organisationId }
        })
      } catch (err) {
        me.$error(err)
      }
    }
  }
}
</script>
