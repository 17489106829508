<template>
  <v-card class="elevation-5">
    <v-card-text class="pl-9 pt-8 pb-1">
      <span class="headline">
        {{ $t('User list') }}
      </span>
    </v-card-text>
    <v-data-table
      :items="users"
      hide-default-footer
      class="user-table"
      item-key="name"
      disable-pagination
    >
      <template v-slot:header>
        <thead>
          <tr>
            <th>{{ $t('Name') }}</th>
            <th>{{ $t('Email') }}</th>
            <th>
              <v-layout justify-end>{{ $t('Edit') }}</v-layout>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:body="{ items }">
        <!-- TODO: Row function to single element, MUST NOT override action button function -->
        <tbody>
          <tr v-for="item in items" :key="item.name">
            <td>{{ item.firstname }} {{ item.lastname }}</td>
            <td>{{ item.email }}</td>
            <td>
              <v-layout justify-end>
                <v-icon right @click="openUser(item.id)">mdi-pencil</v-icon>
              </v-layout>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>
<style lang="sass" scoped>
.user-table
  padding: 24px

  tbody tr:nth-of-type(odd)
    background-color: #EFEFEF

  td
    border: none !important
    &:first-child
      border-top-left-radius: 6px
      border-bottom-left-radius: 6px
    &:last-child
      border-top-right-radius: 6px
      border-bottom-right-radius: 6px
      width: 212px
  tr:hover
    cursor: pointer

  thead
    tr
      th
        font-weight: 300
        font-size: 16px !important
        border: none !important

.duplicate
  margin-left: 10px
</style>
<script>
// TODO: Chechk functionality and styling with other cases when we get those

export default {
  data: () => ({
    users: []
  }),

  created() {
    let me = this
    me.users = me.$store.getters['users/usersByOrganisation'](
      me.$route.params.organisationId
    )
  },

  methods: {
    async openUser(id) {
      let me = this
      await me.$redirect({
        name: 'user-editor',
        params: { userId: id }
      })
    }
  }
}
</script>
