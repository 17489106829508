<template>
  <div>
    <v-form v-model="validForm">
      <v-row>
        <v-col lg="6">
          <v-text-field
            v-model="id"
            :label="$t('User ID')"
            filled
            disabled
          ></v-text-field>
        </v-col>

        <v-col lg="6">
          <v-text-field
            v-model="organisationId"
            :label="$t('Organisation ID')"
            filled
            required
            disabled
            :rules="$rules.required"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col lg="6">
          <v-text-field
            v-model="firstname"
            :label="$t('Firstname')"
            filled
            required
            :rules="$rules.required"
          ></v-text-field>
        </v-col>

        <v-col lg="6">
          <v-text-field
            v-model="lastname"
            :label="$t('Lastname')"
            filled
            required
            :rules="$rules.required"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col lg="6">
          <v-text-field
            v-model="email"
            :label="$t('Email')"
            filled
            required
            :rules="$rules.email"
          ></v-text-field>
        </v-col>

        <v-col lg="6">
          <v-text-field
            v-model="password"
            :label="$t('Password')"
            filled
            type="password"
            required
            :rules="$rules.password"
            autocomplete="new-password"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import FormMixin from '@/mixins/FormMixin'

export default {
  mixins: [FormMixin],
  props: {
    inModal: Boolean
  },
  data: () => ({
    validForm: false
  }),
  computed: {
    id: {
      get() {
        return this.$store.state.users.editor.id
      },
      set(value) {
        this.$store.commit('users/set', ['editor.id', value])
      }
    },
    organisationId: {
      get() {
        return this.$store.state.users.editor.organisation
      },
      set(value) {
        this.$store.commit('users/set', ['editor.organisation', value])
      }
    },
    firstname: {
      get() {
        return this.$store.state.users.editor.firstname
      },
      set(value) {
        this.$store.commit('users/set', ['editor.firstname', value])
      }
    },
    lastname: {
      get() {
        return this.$store.state.users.editor.lastname
      },
      set(value) {
        this.$store.commit('users/set', ['editor.lastname', value])
      }
    },
    email: {
      get() {
        return this.$store.state.users.editor.email
      },
      set(value) {
        this.$store.commit('users/set', ['editor.email', value])
      }
    },
    password: {
      get() {
        return this.$store.state.users.editor.password
      },
      set(value) {
        this.$store.commit('users/set', ['editor.password', value])
      }
    }
  },
  watch: {
    validForm(value) {
      this.$emit('is-valid', value)
    }
  }
}
</script>
